import { render, staticRenderFns } from "./Web3Modal.vue?vue&type=template&id=41db9733&scoped=true&"
import script from "./Web3Modal.vue?vue&type=script&lang=js&"
export * from "./Web3Modal.vue?vue&type=script&lang=js&"
import style0 from "./Web3Modal.vue?vue&type=style&index=0&id=41db9733&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "41db9733",
  null
  
)

export default component.exports