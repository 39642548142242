import { render, staticRenderFns } from "./PageTab.vue?vue&type=template&id=f141a7a4&scoped=true&"
import script from "./PageTab.vue?vue&type=script&lang=js&"
export * from "./PageTab.vue?vue&type=script&lang=js&"
import style0 from "./PageTab.vue?vue&type=style&index=0&id=f141a7a4&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f141a7a4",
  null
  
)

export default component.exports